import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-VP7XRHB9TQ';
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-VP7XRHB9TQ');
  }, []);

  const zipData = [
    { zip: [94507], city: "Alamo", url: "https://alamo.doap.com" },
    { zip: [94002, 94005, 94010, 94011, 94014, 94015, 94019, 94022, 94025, 94027, 94028, 94030, 94038, 94044, 94061, 94062, 94063, 94065, 94066, 94070, 94080, 94112, 94128], city: "Belmont", url: "https://belmont.doap.com" },
    { zip: [94022, 94023, 94024, 94040, 94041, 94042, 94043, 94085, 94086, 94087, 94088, 94089, 94301, 94302, 94303, 94304, 94305, 94306, 94309, 94401, 94402, 94403, 94404], city: "Mountain View", url: "https://mountainview.doap.com" },
    { zip: [94528, 94547, 94552, 94556, 94619, 94605, 94516, 94575], city: "Concord", url: "https://concord.doap.com" },
    { zip: [94506, 94526, 94528, 94547, 94552, 94556, 94619, 94605, 94516, 94575], city: "Danville", url: "https://danville.doap.com" },
    { zip: [94568, 94552, 94586, 94517, 94536], city: "Dublin", url: "https://dublin.doap.com" },
    { zip: [94402, 94403, 94002, 94030, 94070, 94404, 94065, 94066, 94080, 94063, 94128, 94401, 94015, 94044, 94134, 94014, 94124], city: "Hillsborough", url: "https://hillsborough.doap.com" },
    { zip: [94549, 94583, 94553, 94598, 94575, 94556, 94708, 94707, 94709, 94618, 94704, 94517, 94720, 94705, 94710, 94712], city: "Lafayette", url: "https://lafayette.doap.com" },
    { zip: [94550, 94551, 94586, 94539], city: "Livermore", url: "https://livermore.doap.com" },
    { zip: [94618, 94705, 94708, 94563, 94611, 94803, 94704, 94530, 94707, 94556, 94804, 94564, 94619, 94805, 94553, 94709, 94609, 94516, 94610, 94575, 94706, 94710, 94702], city: "Berkeley", url: "https://berkeley.doap.com" },
    { zip: [94566, 94588], city: "Pleasanton", url: "https://pleasanton.doap.com" },
    { zip: [94512, 94513, 94565, 94509, 94514, 94531, 94548, 94561, 94571, 94511], city: "Pittsburg", url: "https://pittsburg.doap.com" },
    { zip: [94597, 94523, 94553, 94528, 94575, 94806, 94531, 94564, 94803, 94805, 94547, 94530, 94707, 94005], city: "Pleasant Hill", url: "https://pleasanthill.doap.com" },
    { zip: [94528, 94583, 94582, 94517, 94552, 94556, 94536, 94537], city: "San Ramon", url: "https://sanramon.doap.com" },
    { zip: [94598, 94595, 94596, 94575, 94556, 94806, 94803, 94805, 94564, 94572, 94801, 94804, 94850, 94547, 94553, 94530, 94707, 94708, 94920], city: "Walnut Creek", url: "https://walnutcreek.doap.com" },
    // Add more cities here
  ];

  const checkZip = () => {
    const userZip = document.getElementById("userZip").value;
    let url;
    for (let city of zipData) {
      if (city.zip.includes(+userZip)) {
        url = city.url;
        break;
      }
    }
    const messageElement = document.getElementById("message");
    if (url) {
      window.location.href = url;
    } else {
      messageElement.innerText = "No matching zip code found. Please try again.";
    }
  };

  return (
    <div className="container">
      <div className="logo">
        <a href="https://www.doap.com">
          <img src="https://www.doap.com/doap-logo-wording.png" alt="Doap logo" />
        </a>
      </div>
      <div className="contact-info">
        <p>Phone: (833)289-3627</p>
        <p>Email: info@doap.com</p>
      </div>
      <div className="form-container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkZip();
          }}
        >
          <input type="text" id="userZip" placeholder="Enter your zip code" />
          <br />
          <label>
            <input type="checkbox" name="age" defaultChecked />
            I am over 21
          </label>
          <br />
          <input type="submit" value="Shop!" />
        </form>
        <div id="message"></div>
      </div>
      <br />
      <a href="https://www.doap.com">
        <img
          src="https://www.doap.com/doapbanner.webp"
          alt="DOAP banner"
          width="100%"
        />
      </a>
    </div>
  );
}

export default App;

